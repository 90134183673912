exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-paid-social-advertising-tsx": () => import("./../../../src/pages/services/paid-social-advertising.tsx" /* webpackChunkName: "component---src-pages-services-paid-social-advertising-tsx" */),
  "component---src-pages-services-social-content-production-tsx": () => import("./../../../src/pages/services/social-content-production.tsx" /* webpackChunkName: "component---src-pages-services-social-content-production-tsx" */),
  "component---src-pages-services-social-media-management-tsx": () => import("./../../../src/pages/services/social-media-management.tsx" /* webpackChunkName: "component---src-pages-services-social-media-management-tsx" */),
  "component---src-pages-services-social-media-strategy-tsx": () => import("./../../../src/pages/services/social-media-strategy.tsx" /* webpackChunkName: "component---src-pages-services-social-media-strategy-tsx" */)
}

