module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-e0b08069b2/0/cache/gatsby-plugin-manifest-npm-5.7.0-ef15e52c51-5c3a69fb9c.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"433622e2b3fad03b584be2ce8732db46"},
    },{
      plugin: require('../.yarn/__virtual__/@chakra-ui-gatsby-plugin-virtual-35ac504bdc/0/cache/@chakra-ui-gatsby-plugin-npm-3.1.2-3cb3af91a3-bc67f5bbc0.zip/node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":false,"isBaseProvider":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-omni-font-loader-virtual-763d76e543/0/cache/gatsby-omni-font-loader-npm-2.0.2-f5ca2a2c0a-6862e13b8d.zip/node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap"}]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-c089ba3aaa/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
