import {
  theme,
  extendTheme,
  baseTheme,
  withDefaultColorScheme,
} from '@chakra-ui/react';

export { baseTheme };

const { blue, pink, gray, white, blackAlpha } = theme.colors;

const colors = {
  primary: {
    ...blue,
    main: blue[600],
    on: white,
    container: blue[50],
    onContainer: blue[900],
  },
  secondary: {
    ...pink,
    main: pink[600],
    on: white,
    container: pink[50],
    onContainer: pink[900],
  },
  background: {
    main: gray[50],
    on: gray[900],
  },
  surface: {
    main: gray[50],
    on: gray[900],
  },
  text: {
    normal: blackAlpha[800],
    muted: blackAlpha[600],
  }
};

export default extendTheme(
  {
    colors,
    fonts: {
      heading: `'Inter', sans-serif`,
      body: `'Inter', sans-serif`,
    },
    components: {
      Container: {
        baseStyle: {
          maxW: 'container.md',
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: colors.primary })
);
